<template>
  <div class="pl-5 pr-5">
    <v-card rounded="xl" elevation="0">
      <v-card-title>
        <v-tabs color="#031738">
          <v-tab class="ml-4" @click="selectedView = '1'">
            Real Time Server Errors
          </v-tab>
          <v-tab @click="selectedView = '2'">
            List of all possible errors
          </v-tab>
          <v-spacer />
          <!-- <v-btn
            style="position: relative; top: 5px;"
            dark
            color="red"
            @click="deleteAllErrors"
          >
            Delete All Errors
          </v-btn> -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                fab
                small
                class="mx-3 mb-5 elevation-0"
                color="red lighten-5"
                v-bind="attrs"
                v-on="on"
                @click="deleteAllErrors"
              >
                <v-icon
                  size="30"
                  dark
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete all errors</span>
          </v-tooltip>
        </v-tabs>
        <!-- <v-spacer /> -->
        <!-- <h1 class="mb-6">
        Errors ({{ errors.length }})
      </h1> -->
        <!-- <v-btn
          dark
          color="red"
          @click="deleteAllErrors"
        >
          Delete All Errors
        </v-btn> -->
      </v-card-title>
    </v-card>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="mt-5"
      >
        <!-- <v-row class="mt-5">
          <h1 class="mb-6">
            Errors ({{ errors.length }})
          </h1>
          <v-spacer />
          <v-btn
            dark
            color="red"
            @click="deleteAllErrors"
          >
            Delete All Errors
          </v-btn>
        </v-row> -->
        <v-card rounded="xl" elevation="0">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  Error
                </th>
                <th class="text-left">
                  Decription
                </th>

                <th class="text-left">
                  Delete Error
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="error in errors"
                :key="error.id"
              >
                <td>{{ dayjs(error.createdAt).format('DD/MM HH:mm') }}</td>
                <td>{{ error.error }}</td>
                <td>{{ error.info }}</td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        fab
                        small
                        class="mx-3 elevation-0"
                        color="red lighten-5"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteError(error.id)"
                      >
                        <v-icon
                          size="20"
                          dark
                          color="red"
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete this error</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div v-if="!errors.length" class="text-center mt-5 pb-5 green--text">
            <v-icon color="green" size="50">
              mdi-check-circle
            </v-icon>
            <div class="mt-5">
              All good
            </div>
            <v-icon color="green" class="mt-5">
              mdi-emoticon-happy-outline
            </v-icon>
          </div>
        </v-card>
      </div>
      <div v-else-if="selectedView === '2'" key="2">
        <v-flex class="mt-5">
          <h1>
            List of API errors
          </h1>
          <h5 class="mb-6">
            Errors which may occure
          </h5>
          <v-spacer />
        </v-flex>
        <v-card>
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              @click="selectedView = '3'"
            >
              Add API Error
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="errorListHeaders"
            :items="errorList"
            :search="search"
            :loading="showProgress"
            loading-text="Loading... Please wait"
            show-group-by
          />
        </v-card>
      </div>
      <div v-else-if="selectedView === '3'" key="3">
        <v-form
          ref="formErr"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="errorId"
            :counter="40"
            :rules="errorIdRule"
            label="Error Id"
            required
          />

          <v-text-field
            v-model="errorInfo"
            :counter="100"
            :rules="errorInfoRule"
            label="Error Info"
            required
          />

          <v-select
            v-model="group"
            :items="errorGroup"
            label="Error Group"
          />

          <v-btn
            color="success"
            class="mr-4"
            :disabled="!valid || !errorId || !errorInfo || !group"
            @click="addToErrorList"
          >
            <div v-if="btnLoading">
              <v-progress-circular
                indeterminate
                color="white"
              />
            </div>
            <div v-else>
              Submit
            </div>
          </v-btn>
        </v-form>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'Errors',
  components: {
  },
  data: () => ({
    dayjs,
    selectedView: '1',
    showProgress: false,
    btnLoading: false,
    // disabled: false,
    valid: false,
    errorList: [],
    pagination: {},
    errorId: '',
    errorIdRule: [
      v => !!v || 'Error ID is required'
    ],
    errorInfo: '',
    errorInfoRule: [
      v => !!v || 'Error Info is required'
    ],
    search: '',
    errorGroup: ['User authentication', 'User Data', 'Property Scenes', 'Property Devices', 'Gateway', 'Property Rooms', 'Property Notifications', 'AppUsers', 'Property Automation'],
    group: '',
    errorListHeaders: [
      { text: '#', value: 'id' },
      { text: 'Error ID', value: 'errorId' },
      { text: 'Error Info', value: 'errorInfo' },
      { text: 'Error Group', value: 'group' }
    ]
  }),
  computed: {
    errors () {
      return this.$store.state.errors
    }
  },
  created () {
    this.$store.dispatch('getErrors')
    this.getErrorList()
  },
  methods: {
    getErrorList () {
      this.showProgress = true
      this.$store.dispatch('getErrorList').then(response => {
        this.errorList = response.data
        this.showProgress = false
      })
    },
    addToErrorList () {
      this.$refs.formErr.validate()
      if (this.valid) {
        this.btnLoading = true
        const data = {
          errorId: this.errorId,
          errorInfo: this.errorInfo,
          group: this.group
        }
        this.$store.dispatch('addToErrorList', data).then(response => {
          this.getErrorList()
          this.btnLoading = false
          this.selectedView = '2'
        })
      }
    },
    deleteError (id) {
      this.$store.dispatch('deleteError', id).then(response => {
        this.$store.dispatch('getErrors')
      })
    },
    deleteAllErrors () {
      this.$store.dispatch('deleteAllErrors').then(response => {
        this.$store.dispatch('getErrors')
      })
    }
  }
}
</script>
